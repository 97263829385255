<template>
  <div class="financial-calculator">
    <b-container>
      <h1 class="my-4" v-if="page">{{ page.title }}</h1>
      <b-nav tabs content-class="mt-3" class="calculator-tabs" lazy justified align="center">
        <!-- <b-nav-item to="#tab1" :active="$route.hash === '#tab1' || $route.hash === ''">
          <div class="d-flex align-items-center justify-content-center">
            <i class="icon icon-calculator-type1" :title="$t('按揭貸款')"></i>
            <strong class="mx-2">{{ $t('按揭貸款') }}</strong>
          </div>
        </b-nav-item> -->
        <b-nav-item to="#tab2" :active="$route.hash === '#tab1' || $route.hash === '' || $route.hash === '#tab2'">
          <div class="d-flex align-items-center justify-content-center">
            <i class="icon icon-calculator-type2" :title="$t('定期存款')"></i>
            <strong class="mx-2">{{ $t('定期存款') }}</strong>
          </div>
        </b-nav-item>
        <b-nav-item to="#tab3" :active="$route.hash === '#tab3'">
          <div class="d-flex align-items-center justify-content-center">
            <i class="icon icon-calculator-type3" :title="$t('計算供款')"></i>
            <strong class="mx-2">{{ $t('計算供款') }}</strong>
          </div>
        </b-nav-item>
      </b-nav>
      <div class="tab-content">
        <div :class="['tab-pane', { active: $route.hash === '#tab4' }]" class="content my-4">
          <h3 class="my-3">{{ $t('按揭貸款') }}</h3>
          <p>
            <b>{{ $t('請輸入以下資料，即可計算出物業按揭的每月供款額') }} ：</b>
          </p>
          <div class="box my-4">
            <!-- 貸款金額 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="amount">{{ $t('貸款金額') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group :append="$t('元')">
                  <b-form-input id="amount" v-model="loans.amount" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 年利率 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="rate">{{ $t('年利率') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group append="%">
                  <b-form-input id="rate" v-model="loans.rate" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 供款年期 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="years">{{ $t('年期') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group :append="$t('年')">
                  <b-form-input id="years" v-model="loans.years" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 每月供款額 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                {{ $t('每月供款額') }}
              </b-col>
              <b-col sm="7" class="input-label ans">
                {{ loans.result }}
              </b-col>
            </b-row>
            <!-- 計算 重置 -->
            <div class="btn-box">
              <b-button pill @click="computeLoans()" class="calculator-btn">{{ $t('計算') }}</b-button>
              <b-button pill @click="resetResult('loans')" class="reset-btn">{{ $t('重置') }}</b-button>
            </div>
          </div>
        </div>
        <div
          :class="['tab-pane', { active: $route.hash === '#tab2' || $route.hash === '#tab1' || $route.hash === '' }]"
          class="content my-4"
        >
          <h3 class="my-3">{{ $t('定期存款') }}</h3>
          <p>
            <b>{{ $t('定期存款公式') }} ：</b>
          </p>
          <div class="box my-4">
            <!-- 年利率 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="rate">{{ $t('年利率') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group append="%">
                  <b-form-input id="rate" v-model="timeDeposits.rate" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 期數 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="years">{{ $t('期數') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group :append="$t('月')">
                  <b-form-input id="years" v-model="timeDeposits.months" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 本金 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="amount">{{ $t('本金') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group :append="$t('元')">
                  <b-form-input id="amount" v-model="timeDeposits.amount" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 期滿後總額 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                {{ $t('期滿後總額') }}
              </b-col>
              <b-col sm="7" class="input-label ans">
                {{ timeDeposits.result }}
              </b-col>
            </b-row>
            <!-- 計算 重置 -->
            <div class="btn-box">
              <b-button pill @click="computeTimeDeposits()" class="calculator-btn">{{ $t('計算') }}</b-button>
              <b-button pill @click="resetResult('timeDeposits')" class="reset-btn">{{ $t('重置') }}</b-button>
            </div>
          </div>
        </div>
        <div :class="['tab-pane', { active: $route.hash === '#tab3' }]" class="content my-4">
          <h3 class="my-3">{{ $t('計算供款') }}</h3>
          <div class="box my-4">
            <!-- 總貸款額 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="amount">{{ $t('總貸款額') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group :append="$t('元')">
                  <b-form-input id="amount" v-model="contribution.amount" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 利率 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="rate">{{ $t('利率') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group :append="$t('厘')">
                  <b-form-input id="rate" v-model="contribution.rate" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 年期 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                <label for="years">{{ $t('年期') }}</label>
              </b-col>
              <b-col sm="7">
                <b-input-group :append="$t('年')">
                  <b-form-input id="years" v-model="contribution.years" type="number"></b-form-input>
                </b-input-group>
              </b-col>
            </b-row>
            <!-- 還款方法 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                {{ $t('還款方法') }}
              </b-col>
              <b-col sm="7" class="text">
                {{ $t('每月還款') }}
              </b-col>
            </b-row>
            <!-- 每月供款額 -->
            <b-row class="my-1">
              <b-col sm="3" class="input-label">
                {{ $t('每月供款額') }}
              </b-col>
              <b-col sm="7" class="input-label ans">
                {{ contribution.result }}
              </b-col>
            </b-row>
            <!-- 計算 重置 -->
            <div class="btn-box">
              <b-button pill @click="computeContribution()" class="calculator-btn">{{ $t('計算') }}</b-button>
              <b-button pill @click="resetResult('contribution')" class="reset-btn">{{ $t('重置') }}</b-button>
            </div>
          </div>
        </div>
      </div>
    </b-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import updateSeoInfo from '@/utils/meta'
import Lifecycle from '@/mixins/lifecycle'
export default {
  mixins: [Lifecycle],
  data() {
    return {
      loans: {
        amount: '100000',
        rate: '9.0000',
        years: '10',
        result: ''
      },
      timeDeposits: {
        amount: '100000',
        rate: '2.0000',
        months: '10',
        result: ''
      },
      contribution: {
        amount: '100000',
        rate: '9.0000',
        years: '10',
        result: ''
      }
    }
  },
  computed: {
    ...mapState('common', ['page']),
    alreadyInited: function() {
      return !!this.menu
    }
  },
  serverPrefetch() {
    return this.fetchData()
  },
  seoInfo() {
    try {
      return {
        title: this.page.metaTitle,
        description: this.page.metaDescription || this.page.metaTitle,
        keywords: this.page.metaKeywords || this.page.metaTitle,
        metas: this.page.metaExtras
      }
    } catch (err) {}
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    // 重置按鈕
    resetResult(type) {
      switch (type) {
        case 'loans':
          this.loans = {
            amount: '100000',
            rate: '9.0000',
            years: '10',
            result: ''
          }
          break
        case 'timeDeposits':
          this.timeDeposits = {
            amount: '100000',
            rate: '2.0000',
            months: '10',
            result: ''
          }
          break
        case 'contribution':
          this.contribution = {
            amount: '100000',
            rate: '9.0000',
            years: '10',
            result: ''
          }
          break
        default:
          break
      }
    },
    // 計算 按揭貸款 每月供款額
    computeLoans() {
      if (this.Checking()) {
        var term = 1 + (this.loans.rate * 0.01 * 1.01) / 12
        var fterm = 1 + (this.loans.rate * 0.01 * 1.01) / 12
        var mpay, ans
        for (var i = 1; i < this.loans.years * 12; i++) {
          fterm = fterm * term
        }
        mpay = (((this.loans.amount * this.loans.rate * 0.01 * 1.01) / 12) * fterm) / (fterm - 1)
        ans = Math.round(mpay)
        ans = this.format(ans.toString())
        this.loans.result = ans
      } else {
        this.resetResult('loans')
      }
    },
    // 計算 定期存款 期滿後總額
    computeTimeDeposits() {
      if (this.CheckingMonth()) {
        //Percentage
        var yearlyRateValue = this.timeDeposits.rate / 100
        var period = this.timeDeposits.months
        var principalAmount = this.timeDeposits.amount
        var result = (1 + (yearlyRateValue * period) / 12) * principalAmount

        var ans = result.toString()

        //two digi
        var index = ans.indexOf('.')

        if (index > 0) {
          ans = ans.substring(0, index)
        }
        this.timeDeposits.result = this.format(ans)
      } else {
        this.resetResult('timeDeposits')
      }
    },
    // 計算供款
    computeContribution() {
      if (this.CheckingContribution()) {
        var ratio = 1.0 / 12.0
        var times = 12 * this.contribution.years
        var result = this.contribution.amount
        result *= (this.contribution.rate * ratio) / 100
        result *= Math.pow(1.0 + (this.contribution.rate / 100) * ratio, times)
        result /= Math.pow(1.0 + (this.contribution.rate / 100) * ratio, times) - 1.0
        this.contribution.result = this.formatMoney(Math.round(result * 100) / 100)
      }
    },
    // 格式 result
    format(price) {
      var lth, pos
      lth = price.length
      pos = price.indexOf('.')
      if (pos == -1) price = price + '.00'
      if (lth - pos == 2) price = price + '0'
      if (price.indexOf('e') == -1) {
        lth = price.length
        pos = lth - 3
      }
      if (pos - 3 > 0) {
        while (pos - 3 > 0) {
          price = price.substring(0, pos - 3) + ',' + price.substring(pos - 3, lth)
          pos -= 3
          lth = price.length
        }
      }
      price = '$' + price
      return price
    },
    // 檢查 按揭貸款填寫的數據格式
    Checking() {
      if (this.loans.amount <= 0) {
        alert(this.$t('貸款金額不能少於或等於零！'))
        return false
      }
      if (isNaN(this.loans.amount)) {
        alert(this.$t('貸款金額輸入錯誤！'))
        return false
      }
      if (this.loans.rate <= 0) {
        alert(this.$t('年利率不能少於或等於零！'))
        return false
      }
      if (isNaN(this.loans.rate)) {
        alert(this.$t('年利率輸入錯誤！'))
        return false
      }
      var patten = /^([0-9]{1}[0-9]*)([.]{1}[0-9]{1,4})?$/
      if (!patten.test(this.loans.rate)) {
        alert(this.$t('年利率小数位不能多于4位！'))
        return false
      }
      if (this.loans.years <= 0) {
        alert(this.$t('供款年期不能少於或等於零！'))
        return false
      }
      var vArr = this.loans.years.match(/^[0-9]+$/)
      if (!vArr) {
        alert(this.$t('供款年期只能輸入整數!'))
        return false
      }
      if (isNaN(this.loans.years)) {
        alert(this.$t('供款年期輸入錯誤！'))
        return false
      }
      return true
    },
    // 檢查 定期存款填寫的數據格式
    CheckingMonth() {
      if (this.timeDeposits.rate <= 0) {
        alert(this.$t('年利率不能少於或等於零！'))
        return false
      }
      if (isNaN(this.timeDeposits.rate)) {
        alert(this.$t('年利率輸入錯誤！'))
        return false
      }
      var patten = /^([0-9]{1}[0-9]*)([.]{1}[0-9]{1,4})?$/
      if (!patten.test(this.timeDeposits.rate)) {
        alert(this.$t('年利率小数位不能多于4位！'))
        return false
      }
      if (this.timeDeposits.months < 0) {
        alert(this.$t('期数輸入錯誤！'))
        return false
      }
      var vArr = this.timeDeposits.months.match(/^[0-9]+$/)
      if (!vArr) {
        alert(this.$t('期数只能輸入整數!'))
        return false
      }

      if (isNaN(this.timeDeposits.amount)) {
        alert(this.$t('本金輸入錯誤！'))
        return false
      }
      return true
    },
    // 檢查 計算供款填寫的數據格式
    CheckingContribution() {
      if (isNaN(eval(this.contribution.amount))) {
        alert('Invalid input')
        return false
      }
      if (isNaN(eval(this.contribution.rate))) {
        alert('Invalid input')
        return false
      }
      if (isNaN(eval(this.contribution.years))) {
        alert('Invalid input')
        return false
      }
      return true
    },
    fetchData() {
      let path = this.$route.path.replace(`/${this.$route.params.lang}/`, '')
      path = path.replace(`preview/`, '')
      const cookies = this.$ssrContext && this.$ssrContext.cookies
      const { preview, id } = this.$route.query
      return new Promise((resolve, reject) => {
        Promise.all([
          this.fetchInitData(),
          this.$store.dispatch('common/FETCH_PAGE', {
            path,
            cookies,
            preview,
            id
          })
        ])
          .finally(() => {
            resolve(updateSeoInfo.call(this))
          })
          .catch(reject)
      })
    },
    // 計算供款 格式化
    formatMoney(inVal) {
      var strIn = new String(inVal)
      var dollar, cent, count, result
      if (strIn.indexOf('.') == -1) {
        dollar = strIn
        cent = ''
      } else {
        dollar = strIn.substring(0, strIn.indexOf('.'))
        cent = strIn.substring(strIn.indexOf('.'), strIn.length)
      }
      count = dollar.length % 3
      if (count == 0) {
        count = 3
      }
      result = dollar.substring(0, count)
      count += 3
      while (count <= dollar.length) {
        result += ','
        result += dollar.substring(count - 3, count)
        count += 3
      }
      result += cent
      return result + this.$t('元')
    }
  }
}
</script>
